<template>
	<div>
		<div class="nian" style="margin-top: 20PX;">
			<div style="display: flex;align-items: center;">
				<img src="../../assets/image/runproject.png" alt="">
				<p>项目</p>
			</div>
			<div class="select">
				<!-- <el-select v-model="info.projectName" placeholder="请选择" filterable clearable @change="getxm">
					<el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
						:value="item.itemText">
					</el-option>
				</el-select> -->

				<a-select option-label-prop="label"  placeholder="请选择" v-model="info.projectName" @change="getxm" allowClear>
					<a-select-option v-for="item in options1" :key="item.itemValue" :value="item.itemText" :label="item.itemText">
						<a-icon type="check" v-if="item.itemText===info.projectName"/> 
						{{item.itemText}}
					</a-select-option>
				</a-select>
			</div>
		</div>
		<div class="xian2"></div>
		<div class="nian">
			<div style="display: flex;align-items: center;">
				<img src="../../assets/image/project2.png" alt="">
				<p>小项</p>
			</div>
			<div class="select">
				<!-- <el-select v-model="info.subProject" placeholder="请选择" clearable @change="getxm">
					<el-option v-for="item in options2" :key="item" :label="item" :value="item">
					</el-option>
				</el-select> -->

				<a-select option-label-prop="label"  placeholder="请选择" v-model="info.subProject" @change="getxm" allowClear>
					<a-select-option v-for="item in options2" :key="item" :value="item" :label="item">
						<a-icon type="check" v-if="item===info.subProject"/> 
						{{item}}
					</a-select-option>
				</a-select>
			</div>
		</div>
		<div class="xian2"></div>
		<div class="nian">
			<div style="display: flex;align-items: center;">
				<img src="../../assets/image/project.png" alt="">
				<p>组别</p>
			</div>
			<div class="select">
				<!-- <el-select v-model="info.groupName" placeholder="请选择" clearable @change="getxm">
					<el-option v-for="item in options3" :key="item" :label="item" :value="item">
					</el-option>
				</el-select> -->

				<a-select option-label-prop="label"  placeholder="请选择" v-model="info.groupName" @change="getxm" allowClear>
					<a-select-option v-for="item in options3" :key="item" :value="item" :label="item">
						<a-icon type="check" v-if="item===info.groupName"/> 
						{{item}}
					</a-select-option>
				</a-select>
			</div>
		</div>
		<div class="xian2"></div>
		<div class="but" @click="getxm2">
			搜索
		</div>

		<div class="table">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%;" :header-cell-style="{ background: 'rgba(82, 180, 255, 0.14)' }">
				<el-table-column type="index" label="序号" align="center" width="55">
				</el-table-column>
				<el-table-column prop="projectName" label="项目" align="center">
				</el-table-column>
				<el-table-column prop="matchName" label="运动会名称" align="center">
				</el-table-column>
				<el-table-column prop="createTime" label="时间" align="center">
					<template slot-scope="scope">
						{{ scope.row.conferenceDate | format}}
					</template>
				</el-table-column>
				<el-table-column prop="matchPlace" label="地点" align="center">
				</el-table-column>
				<el-table-column prop="groupName" label="组别" align="center">
				</el-table-column>
				<el-table-column prop="subProject" label="小项" align="center">
				</el-table-column>
				<el-table-column prop="score" label="成绩" align="center">
				</el-table-column>
				<el-table-column prop="athleteName" label="创造者" align="center">
				</el-table-column>
				<el-table-column prop="statisticalYear" label="统计年份" align="center">
				</el-table-column>
				<el-table-column prop="remarks" label="备注" align="center">
				</el-table-column>
			</el-table>
			<div v-if="tableData.length == 0" style="margin-top: 10PX;">请选择项目</div>
		</div>


		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :type="2" :go="12" :pageSize="12" :pageNo="info.pageNo"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		props: {
			id: {
				type: [Number,String],
				default: ''
			},
			pid: {
				type: [Number,String],
				default: ''
			},
		},
		data() {
			return {
				options1: [],
				options2: [],
				options3: [],
				tableData: [],
				total: 0,
				info: {
					pageNo: 1,
					pageSize: 10,
					groupName: undefined,
					projectName: undefined,
					subProject: undefined,
				},
				loading:false
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.sportEventList()
		},
		methods: {
			sportEventList() {
				this.$api.sportEventList({dictValue:'其他'}).then(res => {
					this.options1 = res.data.data.result
				})
			},
			currentchange(val) {
				this.info.pageNo = val
				this.highestRecordGroup()
			},
			getxm(e){
				this.info.pageNo = 1
				this.$api.highestRecordGroup(this.info).then(res => {
					if (res.data.data.result.records == null) return
					
					let options2 = res.data.data.result.records.map(item=>{return item.subProject}) 
					let options3 = res.data.data.result.records.map(item=>{return item.groupName})
					
					let uniqueArray2 = options2.filter((item, index, array) => {
					  return array.indexOf(item) === index
					})
					let uniqueArray3 = options3.filter((item, index, array) => {
					  return array.indexOf(item) === index
					})
					
					this.options2 = uniqueArray2
					this.options3 = uniqueArray3
				})
			},
			getxm2(){
				this.info.pageNo = 1
				this.loading = true
				this.$api.highestRecordGroup(this.info).then(res => {
					if(res.data.data.result.records == null) return
					res.data.data.result.records.forEach(item=>{
						item.athleteName = this.$decrypt(item.athleteName)
					})
					this.tableData = res.data.data.result.records
					this.total = res.data.data.result.total
					this.loading = false
				}).catch(error => {
					this.loading = false
				})
			},
			highestRecordGroup() {
				this.$api.highestRecordGroup(this.info).then(res => {
					if (res.data.data.result.records == null) return
					
					let options1 = res.data.data.result.records.map(item=>{return item.projectName})
					
					let uniqueArray1 = options1.filter((item, index, array) => {
					  return array.indexOf(item) === index
					})
					
					this.options1 = uniqueArray1
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.nian {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 28PX;

		img {
			width: 17PX;
			height: 17PX;
			flex-shrink: 0;
		}

		p {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin-left: 12PX;
			flex-shrink: 0;
		}

		.select ::v-deep .el-select .el-input .el-input__inner {
			border-color: transparent !important;
			background-color: transparent !important;
			box-shadow: none !important;
			color: #000;
		}

		.select ::v-deep .el-select .el-input,
		.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}

		.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
			transform: rotateZ();
		}

		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #C0C4CC;
			font-size: 14PX;
			transition: transform .3s;
			transform: rotateZ(89deg);
			cursor: pointer;
			left: 15PX;
			position: relative;
		}

		.select ::v-deep input::-webkit-input-placeholder {
			color: #000;
		}

		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #000;
		}

		::v-deep .el-input .el-input__inner {
			border: none !important;
			padding: 0;
		}

		::v-deep input::-webkit-input-placeholder {
			color: #000;
		}
	}

	.xian2 {
		margin: 0 28PX;
		height: 1PX;
		background: rgba(194, 194, 194, 0.2);
	}

	.but {
		margin: 20PX 28PX;
		width: calc(100% - 56PX);
		height: 37PX;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
		border-radius: 50PX;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
	}

	.paging {
		margin-bottom: 28PX;
		margin-left: 28PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	.table {
		margin: 20PX 28PX 0;
	}

	.table ::v-deep .el-table thead {
		color: #3283F3;
		font-size: 16PX;
		font-weight: 500;
		font-size: 11PX;
	}

	.table ::v-deep .el-table__header-wrapper {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table th.el-table__cell.is-leaf {
		border-bottom: 1PX solid #333333;
		border-right: 1PX solid #333333;
	}

	.table ::v-deep .el-table td,
	.el-table th.is-leaf,
	.el-table--border,
	.el-table--group {
		border-color: black;
	}

	.table ::v-deep .el-table--border::after,
	.el-table--group::after,
	.el-table::before {
		background-color: black;
	}
</style>